import AI from './static/AI.png'
import Mirror from './static/Mirror.png'

export const NAME = 'Sasan Hakimzadeh';
export const OCCUPATION = 'Technical Product Manager';
export const OCCUPATION_DESCRIPTION = '"Fall in love with the problem, not the solution" \n - Marty Cagan"';

export const GITHUB_LINK = "https://github.com/sasanhakimzadeh"
export const LINKEDIN_LINK = "https://www.linkedin.com/in/sasanhakimzadeh/"
export const EMAIL_LINK = "mailto: sasan.hakimzadeh1@gmail.com"

export const ABOUT = {
    paragraphs : [
        [
            {
                type: 'bold',
                text: 'A problem solver at heart,'
            },
            {
                type: 'text', 
                text : ' I have been fascinated with technology ever since my father surprised me with ' +
                'a GameBoy on a rainy Saturday morning in 2004. ' + 
                'While I lacked the fundamental understanding of Flash ROM, transistors, and floating gate cells  ' +
                ' (all fundamental concepts of the solid state drive, which I would later learn as an Electrical Engineering undergraduate student), ' +
                'I remember being mesmerized by the fact that it knew my high score even after I had turned it off. Admittedly my sense of curiousity has only deepened since then; much to the chagrin of friends who  ' +
                'seem to always have to remind me that I can \"Google It\" and that they \"don\'t know if Australia is wider than the moon\" (spoiler alert: '
            },
            {
                type: 'bold',
                text: 'it is.'
            },
            {
                type: 'text',
                text: ')'
            }

        ],
        [
            { 
                type: 'text',
                text : 'Naturally, I dove headfirst into a career in tech after college, and have never looked back.  ' + 
                'Creating products that customers love has been my latest obsession (sorry, Nintendo), and I am consistently energized by the results and impact that'
            },
            {
                type: 'bold', 
                text : ' product-led growth'
            },
            { 
                type: 'text',
                text : ' can have on an organization. '
            }
        ],
        [
            { 
                type:'bold',
                text : 'Outside of work,  '
            },
            { 
                type:'text',
                text : 'you can find me outside (running, hiking, camping, kayaking, snowboarding), reading, or cooking. I hope this website is able to give you a peek into my life, feel free to connect!' 
            }
        ],
    ]
}

export const EXPERIENCE = {
    experiences : [
        {
            type: 'Job',
            date: 'Sep 2022 - PRESENT',
            name: 'Capital One',
            link: 'https://www.capitalone.com/',
            role: 'Technical Product Manager',
            description: '0-1 product experience in creating platforms to reduce risk across Card Tech, improve developer experience, and minimize operating costs',
            skills: [
                'Agile Software Development',
                'UX/UI design',
                'Communication',
                'Problem Solving'
            ]
        },
        {
            type: 'Job',
            date: 'JULY 2020 - September 2022',
            name: 'Barclays Bank',
            link: 'https://home.barclays/',
            role: 'Software Engineer',
            description: 'I developed software to make sure trading data was always accurate.',
            skills: [
                'Python',
                'TypeScript',
                'SQL',
                'Flask',
                'React',
                'Jenkins',
                'Selenium'
            ]
        },
        {
            type: 'Job',
            date: 'JAN 2019 - SEP 2019',
            name: 'Johnson & Johnson',
            link: 'https://www.jnj.com/',
            role: 'Technical Business Analyst',
            description: 'Built Machine Learning Models to sequence consumer product data hierarchies.',
            skills: [
                'C#',
                'JavaScript',
                'SQL',
                'Vue',
                'ASP.Net',
                'MVC'
            ]
        },
        {
            type: 'Education',
            date: 'SEP 2016 - MAY 2020',
            name: 'Rutgers University',
            link: 'https://soe.rutgers.edu/student-experience/engineering-honors-academy',
            role: 'Honors Academy',
            description: 'I majored in Computer Engineering and minored in Computer Science.',
            skills: [
                'Java',
                'C',
                'SQL',
                'TypeScript',
                'Angular'
            ]
        },
        {
            type: 'Education',
            date: 'SEP 2012 - June 2016',
            name: 'Verona High School',
            link: 'https://www.veronaschools.org/vhs',
            description: 'Where I first was introduced to Computer Science.',
            skills: [
                'Java',
                'C++',
            ]
        }

    ]
}

export const PROJECTS = {
    projects : [
        {
            name: "AI Assistant: SasanAI",
            image: AI,
            align: 'right',
            imageStyle: {
                height: "11rem",
                width: "19rem"
            },
            mobileImageStyle: {
                height: "8rem",
                width: "10rem"
            },
            description: [
                {
                    type: "text",
                    text: "Embedded on this website you can find a chatbot that can answer questions about me."
                },
            ],
            skills: [
                "OpenAI",
            ]
        }
    ]
}

export const SKILLS = {
    skills : [
        {
            name: "Programming",
            list: [
                "Java", 
                "Python", 
                "JavaScript",
                "C", 
                "C#", 
                "SQL", 
                "Swift", 
                "VB", 
                "VBA",
                "HTML/CSS"
            ]
        },
        {
            name: "Frameworks / Databases",
            list: [
                "React", 
                "React Native", 
                "Angular",
                ".Net", 
                "MVC", 
                "Flask", 
                "Selenium", 
                "SQL"
            ]
        },
        {
            name: "DevOps / Cloud Services",
            list: [
                "Jenkins", 
                "Azure DevOps", 
                "Azure Database Hosting", 
                "Azure Application Hosting",
                "AWS S3", 
                "Firebase"
            ]
        },
    ]
}