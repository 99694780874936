import * as config from "./Config"

const handleChatResponse = async (message, currentMessages, addMessage, setIsTyping) => {
    const newMessage = {
      message,
      direction: 'outgoing',
      sender: "user",
    };

    addMessage(newMessage);
    setIsTyping(true);

    try {
      const response = await processMessageToChatGPT([...currentMessages, newMessage]);
      if(response.error){
        const chatGPTResponse = {
            message: "There was an error:\n" + response.error.message,
            sender: "assistant",
          };
        addMessage(chatGPTResponse);
      }else{
        const content = response.choices[0]?.message?.content;
        if (content) {
          const chatGPTResponse = {
            message: content,
            sender: "assistant",
          };
          addMessage(chatGPTResponse);
        }
      }
      
    } catch (error) {
      console.error("Error processing message:", error);
    } finally {
      setIsTyping(false);
    }
  };

  const formatMessage = (message) => {
    for(let nickname in config.nicknames){
        message = message.replaceAll(nickname, config.name);
    }
    message = message.replaceAll(" him", config.name).replaceAll(" his", config.name + "'s").replaceAll(" he", config.name).replaceAll(config.name + "s", config.name + "'s"); 
    return message;
  }

  const processMessageToChatGPT = async (chatMessages) => {
    const apiMessages = chatMessages.filter((messageObject) => messageObject.sender != "default").map((messageObject) => {
      return { 
        role: messageObject.sender, 
        content: formatMessage(messageObject.message)
    };
    });

    const apiRequestBody = {
      "model": "gpt-3.5-turbo",
      "messages": [
        {role: 'system', content: config.chatBotRole},
        ...apiMessages,
      ],
    };
    console.log(apiRequestBody);
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + config.API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    });

    return response.json();
  }

  export default handleChatResponse;